/*
 Template Name: Drixo - Bootstrap 4 Admin Dashboard
 Author: Themesdesign
 Website: www.themesdesign.in
 File: Icons
 */

@import "../icons/font-awesome/scss/font-awesome";
@import "../icons/material-design/materialdesignicons";
@import "../icons/ionicons/scss/ionicons";
@import "../icons/themify-icons/themify-icons";
@import "../icons/dripicons/dripicons";
@import "../icons/typicons/typicons";
